import React, { useState, useEffect, useRef, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import { MdFiberManualRecord, MdSearch } from "react-icons/md";
import FullscreenModal from "./components/fullscreen";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import logo from "./assets/logo2.png";
import { BiFilter } from "react-icons/bi";
import Fuse from "fuse.js";

import AnimatedText from "./components/anitext";

export default function Work({ onLogoChange, projects }) {
  const location = useLocation();
  const [activeBrand, setActiveBrand] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isBModalOpen, setIsBModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const searchRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    if (videoContainerRef.current) {
      videoContainerRef.current.scrollTo(0, 0);
    }
  }, [activeBrand, searchTerm]);
  useEffect(() => {
    if (location.state && location.state.brand) {
      setActiveBrand(location.state.brand);
    }
  }, [location.state]);

  const toggleBrand = (brand) => {
    if (activeBrand === brand) {
      setActiveBrand(null);
    } else {
      setActiveBrand(brand);
    }
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    if (e.target.value) {
      setActiveBrand(null); // Reset the brand filter
    }
  };

  const fuseOptions = {
    keys: ["title", "brand"], // Search in title and brand
    includeScore: true, // Include search score
    threshold: 0.3 // Adjust to control how fuzzy the search should be
  };

  const filteredVideos = useMemo(() => {
    if (searchTerm) {
      const fuse = new Fuse(projects, fuseOptions);
      const results = fuse.search(searchTerm);

      return results
        .map((result) => result.item) // Extract the project data from results
        .filter(
          (project) =>
            project.title !== "Reel" && project.title !== "ReelMobile"
        ); // Exclude "Reel" and "ReelMobile"
    } else {
      // If no search term, return all projects (optionally filter by brand)
      return projects.filter((project) => {
        if (activeBrand && project.brand !== activeBrand) return false;
        return project.title !== "Reel" && project.title !== "ReelMobile";
      });
    }
  }, [projects, activeBrand, searchTerm]);

  const handleVideoClick = (project) => {
    setSelectedVideo(project);
    setIsBModalOpen(true);
  };

  const uniqueBrands = Array.from(
    new Set(projects.map((project) => project.brand))
  ).sort();

  useEffect(() => {
    onLogoChange(logo, "small");
  }, [onLogoChange]);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    show: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 }
  };

  const handleMouseEnter = (e) => {
    const videoElement = e.currentTarget.querySelector("video");

    // Check if the video is ready to play
    if (videoElement.readyState >= 2) {
      setTimeout(() => {
        videoElement.play().catch((error) => {
          console.error("Error attempting to play the video:", error);
        });
      }, 100); // Debounce to avoid play/pause conflict
    }
  };

  const handleMouseLeave = (e) => {
    const videoElement = e.currentTarget.querySelector("video");

    setTimeout(() => {
      videoElement.pause();
    }, 100); // Debounce to avoid play/pause conflict
  };

  return (
    <div className="w-full flex flex-col  bg-black text-white min-h-screen relative">
      <div className="w-full flex flex-col h-full   fixed top-9 p-4 md:mt-16 lg:mt-0 lg:p-16 overflow-auto">
        <div className="lg:flex flex-row hidden items-center justify-between w-full mt-8 flex-wrap">
          <div className="md:basis-1/5 ">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.7 }}
              className="relative"
              ref={searchRef}
            >
              <input
                type="text"
                placeholder="SEARCH"
                className="input text-left w-full bg-black border-red-500 rounded-full text-red-500 uppercase tracking-widest font-semibold text-xs focus:border-white input-sm"
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className="absolute right-2 top-2 text-xl">
                <MdSearch />
              </div>
            </motion.div>
          </div>

          <div className="flex flex-row basis-3/4 gap-4 items-center">
            <button
              className={`rounded-full px-2 gap-1 uppercase tracking-widest hover:bg-transparent flex items-center justify-center py-1 text-xs group ${
                !activeBrand
                  ? "bg-transparent outline-white transition-all duration-200 outline-1 outline"
                  : ""
              }`}
              onClick={() => toggleBrand(null)}
            >
              <MdFiberManualRecord
                className={
                  !activeBrand
                    ? "text-red-500 group-hover:text-white duration-200 text-lg"
                    : "text-transparent"
                }
              />
              All
            </button>
            {uniqueBrands.slice(0, 7).map((brand, index) => (
              <button
                key={index}
                className={`rounded-full gap-1 uppercase tracking-widest hover:bg-transparent px-2 flex items-center justify-center py-1 text-xs group ${
                  activeBrand === brand
                    ? "bg-transparent outline-white transition-all duration-200 outline-1 outline"
                    : ""
                }`}
                onClick={() => toggleBrand(brand)}
              >
                <MdFiberManualRecord
                  className={
                    activeBrand === brand
                      ? "text-red-500 group-hover:text-white duration-200 text-lg"
                      : "text-transparent"
                  }
                />
                {brand && brand}
              </button>
            ))}

            {uniqueBrands.length > 7 && (
              <div className="md:block hidden tracking-widest relative">
                <button
                  className="text-red-500 hover:underline py-1 font-semibold uppercase text-xs text-left"
                  onClick={() => {
                    setDropdownOpen(!dropdownOpen);
                    setShowMore(!showMore);
                  }}
                >
                  {showMore ? (
                    <p className="flex items-center">
                      Show Less
                      <BsArrowUp />{" "}
                    </p>
                  ) : (
                    <p className="flex items-center">
                      Show More
                      <BsArrowDown />{" "}
                    </p>
                  )}
                </button>
                <AnimatePresence>
                  {dropdownOpen && (
                    <motion.div
                      initial="hidden"
                      animate="show"
                      exit="exit"
                      variants={containerVariants}
                      className="absolute bg-black/80   border-red-500 border overflow-y-scroll h-max w-[12rem] max-h-56  text-white right-0 top-12 rounded-xl p-2 shadow-lg z-20"
                    >
                      {uniqueBrands.slice(7).map((brand, index) => (
                        <motion.button
                          variants={itemVariants}
                          key={index}
                          className={`block  w-full text-xs tracking-widest uppercase hover:text-red-500 text-left px-4 py-2 ${
                            activeBrand===brand ? 'text-red-500' : 'text-white'}`}
                          onClick={() => {
                            toggleBrand(brand);
                            setDropdownOpen(false);
                            setShowMore(!showMore);

                          }}
                        >
                          {brand && brand}
                        </motion.button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
        {/* Dropdown for mobile screens */}
        <div className="flex mt-8 md:mt-4 lg:hidden items-center justify-between w-full">
          <div className="relative w-1/2">
            {" "}
            <input
              type="text"
              placeholder="SEARCH"
              className="input w-full bg-black border-red-500 rounded-full text-red-500 uppercase tracking-widest font-semibold text-xs text-left focus:border-white input-xs"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute right-2 top-1 text-lg">
              <MdSearch />
            </div>
          </div>
          <div className="gap-2  flex  items-center relative">
            <p className="text-red-500 text-3xl">
              <BiFilter />
            </p>
            <button
              className="bg-black text-white text-sm px-4 py-1 rounded-full border border-red-500 text-left"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              {activeBrand || "ALL"}
            </button>
            {dropdownOpen && (
              <div className="absolute bg-black/80 w-[12rem] max-h-56  overflow-scroll  border border-red-500 text-white right-0 top-16 rounded-lg shadow-lg z-20">
                <button
                  className="block w-full text-xs font-light tracking-widest text-left px-4 py-2"
                  onClick={() => {
                    toggleBrand(null);
                    setDropdownOpen(false);
                  }}
                >
                  ALL
                </button>
                {uniqueBrands.map((brand, index) => (
                  <button
                    key={index}
                    className="block w-full uppercase text-xs font-light tracking-widest text-left px-4 py-2"
                    onClick={() => {
                      toggleBrand(brand);
                      setDropdownOpen(false);
                    }}
                  >
                    {brand && brand}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        <div
          ref={videoContainerRef}
          className="my-8 md:py-4 hidden bg-black overflow-y-auto  h-full lg:grid grid-cols-3 gap-4 "
        >
          {filteredVideos.map((project) => (
            <motion.div
              key={project.indexId}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={() => handleVideoClick(project)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="relative aspect-video order-first rounded-lg  group w-full"
            >
              <video
                preload="auto"
                controls={false}
                muted
                playsInline
                disablePictureInPicture
                onContextMenu={(e) => e.preventDefault()}
                onError={(e) => {
                  console.error("Error loading video:", e.currentTarget.error);
                }}
              >
                <source src={project.teaser} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                className="absolute inset-0 text-center  flex items-center justify-center"
                onClick={(e) => {
                  const videoElement = e.currentTarget.previousSibling;
                  handleVideoClick(project);
                }}
              >
                <AnimatedText
                  brand={project.brand}
                  title={project.title}
                  isVisible={true}
                  smallText={true}
                />{" "}
              </div>
            </motion.div>
          ))}
        </div>

        {/* MOBILE */}
        <div
          ref={videoContainerRef} // Attach the reference here
          className={`my-8 md:py-4 lg:hidden bg-black overflow-y-auto overflow-x-hidden  grid grid-cols-1 gap-4 justify-items-center ${
            filteredVideos.length > 2 ? "h-full" : "h-max"
          }`}
        >
          
            {filteredVideos.map((project) => (
              <motion.div

                key={project.indexId}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                onClick={() => handleVideoClick(project)}
                className="relative cursor-pointer aspect-video rounded-lg h-max group w-full"
              >
                <video
                  className="object-cover rounded-lg"
                  controls={false}
                  disablePictureInPicture
                  preload="auto"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={project.teaser} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div
                  className="absolute inset-0 text-center flex items-center justify-center"
                  onClick={() => handleVideoClick(project)}
                >
                  <AnimatedText
                    brand={project.brand}
                    title={project.title}
                    isVisible={true}
                    smallText={true}
                  />{" "}
                </div>
              </motion.div>
            ))}

        </div>
      </div>

      {/* Fullscreen modal */}
      {isBModalOpen && (
        <FullscreenModal
          isOpen={isBModalOpen}
          onClose={() => setIsBModalOpen(false)}
          videoProps={
            selectedVideo
              ? {
                  url: selectedVideo.link,
                  title: selectedVideo.title,
                  description: selectedVideo.description,
                  logo: selectedVideo.logo,
                  isActive: true,
                  showControls: true
                }
              : {}
          }
        />
      )}
    </div>
  );
}
