import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdFiberManualRecord } from "react-icons/md";
import Clients from "./clients";
import FlipLink from "./fliptext";
import Overlay from "./overlay";
import ContactDrawer from "./contactus";

export default function Nav({ logoSrc, logoSize }) {
  const [isClientDrawerOpen, setClientDrawerOpen] = useState(false);
  const [hovered, setHovered] = useState(null);
  const [showConnect, setShowConnect] = useState(false);
  const ref = useRef(null);
  const location = useLocation();

  const navItems = [
    { id: 1, name: "Work", link: "/work" },
    { id: 2, name: "Clients", link: "#" },
    { id: 3, name: "Connect", link: "#" }
  ];

  const activeNavItem = navItems.find(
    (item) => item.link === location.pathname
  )?.name;

  const handleNavItemClick = (name) => {
    if (name === "Connect") {
      setClientDrawerOpen(false);
      setShowConnect(!showConnect);
    } else if (name === "Clients") {
      setClientDrawerOpen(true);
      setShowConnect(false);
    } else {
      setClientDrawerOpen(false);
      setShowConnect(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowConnect(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleOverlayClick = () => {
    setClientDrawerOpen(false);
    setShowConnect(false);
  };

  const logoDimensions = {
    large: { width: "12rem", height: "6rem" },
    small: { width: "4rem", height: "2rem" }
  };

  return (
    <>
      <div className="navbar flex justify-between   md:bg-gradient-to-b from-black/50 to to-transparent  space-x-[2rem] md:space-x-0 px-4 fixed z-[104] lg:px-8 text-white top-0">
        <a
          href="/"
          className="h-[2rem] w-[4rem] lg:w-[12rem] lg:h-[6rem] flex items-center"
        >
          <img
            src={logoSrc}
            alt="Logo"
            width={300}
            height={300}
            className="h-full w-full  object-contain"
            style={logoDimensions[logoSize]}
          />
        </a>
        <div className="justify-between  flex">
          <ul className="uppercase font-semibold grid grid-cols-3 tracking-widest ">
            {navItems.map((item) => (
              <li
                key={item.id}
                className={`py-0 basis-1 ${
                  hovered !== null && hovered !== item.id
                    ? "text-neutral-400"
                    : "text-white"
                }`}
                onMouseEnter={() => setHovered(item.id)}
                onMouseLeave={() => setHovered(null)}
              >
                <Link
                  className={`rounded-full gap-1 md:px-4 px-2 py-1 hover:bg-transparent w-max flex items-center justify-center md:py-1 text-xs md:text-sm group ${
                    activeNavItem === item.name
                      ? "bg-transparent outline-white transition-all duration-200 outline-1 outline"
                      : ""
                  }`}
                  to={item.link}
                  onClick={() => handleNavItemClick(item.name)}
                >
                  <MdFiberManualRecord
                    className={
                      activeNavItem === item.name
                        ? "text-red-500 group-hover:text-white text-xs duration-200 font-semibold"
                        : "text-transparent"
                    }
                  />
                  <FlipLink>{item.name}</FlipLink>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Overlay */}
      <Overlay
        isOpen={isClientDrawerOpen || showConnect}
        onClick={handleOverlayClick}
      />

      {/* Client Drawer */}
      <Clients
        isOpen={isClientDrawerOpen}
        onClose={() => setClientDrawerOpen(false)}
      />

      {/* Connect Menu */}
      <ContactDrawer
        isOpen={showConnect}
        onClose={() => setShowConnect(false)}
      />
    </>
  );
}
